
































































































































































@import '@design';
@import '@styleMixins';

.toolbar .theme--light.v-toolbar {
  z-index: 3;
  &,
  .v-toolbar__content {
    > * {
      z-index: 1;
    }
  }
  .v-toolbar__content {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 414px) {
      padding: 0 5px;
    }
  }
}

.nav-left,
.nav-center,
.nav-right {
  flex: 0 1 auto;
}

.nav-menu-cta {
  @include padding-lr(1.5em);

  .v-btn__content {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.nav-route {
  display: inline-block;
  margin: 0 $size-grid-padding;
  text-decoration: none;
  text-transform: uppercase;
  a {
    @extend .motor--font;

    font-size: 1.2rem;
    color: lighten(black, 15);
    text-decoration: none;
    letter-spacing: 0.074em !important;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: $color-treps-blue;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid $color-treps-blue;
  }
} // nav-route

.nav-logo--link {
  line-height: 0;
}
.nav-logo {
  max-width: 150px;
  cursor: pointer;
  &.lg,
  &.md,
  &.sm {
    max-width: 120px;
  }
  &.xs {
    max-width: 80px;
  }
}

.order-now-btn--wrap {
  @media only screen and (max-width: 340px) {
    display: none;
    visibility: hidden;
  }
}
